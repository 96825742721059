import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/TeamMemberLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Better known as Famous Amos he is self-taught in both still and video photography. His work is enjoyed by followers all over the world on social media, and can be viewed at `}<a parentName="p" {...{
        "href": "http://www.famousamosphotography.com"
      }}>{`www.famousamosphotography.com`}</a>{`. His Wildlife and Northern Lights work has been recognized globally on a professional level.`}</p>
    <p>{`Amos Wiebe has always had an affinity for rural life growing up in the country in Northwestern Alberta. Being actively involved on the farm since the age of 13 years, he can operate any piece of equipment with proficiency. Holding a class one license, Amos manages the black CLHbid.com Peterbilts delivering vans to locations as well as overseeing the CLHbid.com picker truck setting up signs.`}</p>
    <p>{`Spending a great deal of time in the outdoors, Amos is at ease working with owners wanting to showcase their rural land. Whether that means using his wide angle low light expertise inside a ranch home, or using his drone to display lush canola fields, his work is always world class. Owners always enjoy Amos’ positive attitude and ability to showcase what they see as important selling points to focus on, in order to achieve the highest value for their land on auction day.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      